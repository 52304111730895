import * as React from 'react';
import { FC, useState, useEffect } from 'react';
import { LeagueMatchAggregateViewModel, LeagueMatchPlayerViewModel, LeagueMatchSet } from '../../commons/leagueMatch/model';
import { default as lodash } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { leagueMatchActions } from '../../commons/leagueMatch/leagueMatchReducer';
import { RootState } from '../../store/rootReducer';
import { ReducerStateProperties, RequestStatus } from '../../commons/baseReducer';
import { responseHandlerActions } from '../../commons/responseHandler/responseHandlerActionReducer';
import { MatchCounter, MatchPlayers, MatchTeam, PlayersFiledLocation } from '../../components/matchCounter';
import { MatchSetResult } from '../../components/matchCounter';
import { SignalRService } from '../../api/signalR.service';
import { signalRStateActions } from '../../commons/singalR/signalRStateReducer';
import { isPageLiveScore } from '../../commons/utils/businessUtils';
import { MatchPointsViewer } from '../../components/matchPointsViewer';
import { BaseProps } from '../../routes';
import { useNavigate, useParams } from 'react-router-dom';
import { NextCounterIndividualMatch } from '../../components/leagueMatch/nextCounterIndividualMatch';
import { getPage } from '../../commons/utils/ui.utils';
import { CourtLeagueMatchIndividualMatchResponse } from '../../commons/courts/model';
import { courtsActions } from '../../commons/courts/courtsActionReducer';
import { getDispatchException, setPlayersInitial } from '../../commons/utils/matchCounterUtils';
import { CourtData } from '../../components/tournament/nextCounterTournament';

type props = BaseProps & {
};

export const LeagueMatchSetCounter: FC<props> = (props) => {
  
  const onbackwardAction = function () {
    hubConnectionLeagueMatch.connection.stop()
    setHubConnectionLeagueMatch(undefined)
  }
  if (window.onpopstate !== onbackwardAction) {
    window.onpopstate = onbackwardAction
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [courtData, setCourtData] = useState<CourtData>(undefined)

  const urlPath = props.isUmpire ? "umpire" : "count";

  const { leagueMatchIndividualMatchId, leagueMatchIdStr, password } = useParams();
  let leagueMatchIndividualMatchIdNumber: number = 0
  if (leagueMatchIndividualMatchId) {
    leagueMatchIndividualMatchIdNumber = new Number(leagueMatchIndividualMatchId).valueOf();
  }
  let leagueMatchId: number;
  if (leagueMatchIdStr) {
    leagueMatchId = new Number(leagueMatchIdStr).valueOf();
  }
  const matchSelector = useSelector<RootState>((state) => state.leagueMatch.leagueMatch) as ReducerStateProperties<LeagueMatchAggregateViewModel>;
  const [match, setMatch] = useState(
    { match: matchSelector, isInit: !lodash.isEmpty(matchSelector.model) }
  )

  useEffect(() => {
    if (isSubmitted && matchSelector.status == RequestStatus.Ok) {
      dispatch(leagueMatchActions.getLeagueMatch(match.match.model.match.leagueMatchId, password, null, isLiveScore || props.isReadonly))
      setIsSubmitted(false);
    }

  }, [isSubmitted, matchSelector])

  useEffect(() => {
    if (lodash.isEmpty(matchSelector.model) && !match.isInit && !lodash.isEmpty(password)) {
      dispatch(leagueMatchActions.getLeagueMatch(leagueMatchId, password, null, isLiveScore || props.isReadonly))
      setMatch({
        ...match,
        isInit: true
      })
    }
    if (!(lodash.isEmpty(matchSelector.model) && lodash.isEqual(match.match, matchSelector))) {
      setMatch({
        ...match,
        match: matchSelector
      })
    }
  }, [matchSelector])
  const [hasConnectSignalRLeagueMatch, setHasConnectSignalRLeagueMatch] = useState(false)
  const [hubConnectionLeagueMatch, setHubConnectionLeagueMatch] = useState<MatchHubConnection | undefined>()
  const [isLiveScore] = useState<boolean>(isPageLiveScore())
  const [liveScoreState, setLiveScoreState] = useState<LiveScoreMatchState>()
  const [signalRService] = useState(new SignalRService())
  const [isLiveScoreStateRetrived, setIsLiveScoreStateRetrived] = useState<boolean>(false)
  const courtAssigmentSelector = useSelector<RootState>(x => {
    return x.courts.courtAssigment
  }) as ReducerStateProperties<CourtLeagueMatchIndividualMatchResponse>
  const [courtAssigmentState, setCourtAssigmentState] = useState<CourtAssigmentState>({
    isInit: false,
    model: undefined
  })
  useEffect(() => {

    if (!lodash.isEqual(courtAssigmentSelector.model, courtAssigmentState.model) &&
      !lodash.isEmpty(courtAssigmentSelector.model)) {
      setCourtAssigmentState({
        ...courtAssigmentState,
        model: courtAssigmentSelector.model
      })
    }

    if (!courtAssigmentState.isInit) {
      dispatch(courtsActions.getCurrentCourt(leagueMatchIndividualMatchIdNumber))
      setCourtAssigmentState({
        ...courtAssigmentState,
        isInit: true
      })
    }
  }, [courtAssigmentState, courtAssigmentSelector])

  const signalrConnectionSetup = (hubConnection: signalR.HubConnection,
    setHubConnection: (hubConnection: MatchHubConnection) => void,
    setHasConnection: (value: boolean) => void,
    hasConnection: boolean,
    groupId: number) => {
    if (!hasConnection) {
      hubConnection.start().then(a => {
        if (isLiveScore) {
          hubConnection.on('matchNewResults', receiveLiveScoreResults)
        } else {
          hubConnection.on('matchNewResults', handleException)
        }

        if (hubConnection.connectionId) {
          hubConnection.invoke("sendConnectionId", hubConnection.connectionId,
            `${groupId}`)
            .then(() => {
              hubConnection.invoke<LiveScoreMatchState>('getMatchLastResults',
                groupId)
                .then(results => {
                  if (!results) {
                    const matchSetResult = getMatchSetResult()
                    const players = getMatchPlayers();
                    if (players) {
                      const playersFiledLocation = setPlayersInitial(players)
                      const initialCurrentServePlayer = playersFiledLocation?.playerA?.playerId
                      const state: LiveScoreMatchState = {
                        ...liveScoreState,
                        matchResults: matchSetResult,
                        playersLocation: playersFiledLocation,
                        currentPlayerServeId: initialCurrentServePlayer
                      }
                      setLiveScoreState(state)
                      individualMatchSendResult(
                        { connection: hubConnection, groupId: leagueMatchIndividualMatchIdNumber },
                        state, courtData
                      )
                    }
                  }
                  receiveLiveScoreResults(results)

                })
            });
          setHubConnection({
            connection: hubConnection,
            groupId: leagueMatchIndividualMatchIdNumber
          })
          setHasConnection(true)
        }
      })
    }
  }
  const initialSignalRConnection = () => {
    const hubConnection = signalRService.getConnection('leaguematch')
    signalrConnectionSetup(hubConnection, setHubConnectionLeagueMatch,
      setHasConnectSignalRLeagueMatch,
      hasConnectSignalRLeagueMatch,
      leagueMatchIndividualMatchIdNumber)
  }
  useEffect(() => {
    if (match?.match?.model) {
      initialSignalRConnection()
    }
  }, [hasConnectSignalRLeagueMatch, courtAssigmentState, match])

  const handleException = (liveScoreState: LiveScoreMatchState) => {
    if (liveScoreState?.exceptions) {
      const { exceptions } = liveScoreState
      exceptions?.forEach(ex => {
        dispatch(responseHandlerActions.call(getDispatchException(ex)))
      })
    }
  }
  const receiveLiveScoreResults = (liveScoreState: LiveScoreMatchState) => {
    if(!lodash.isNil(liveScoreState?.matchResults)){
      setMatchResultFromMatchCounterVariable(liveScoreState?.matchResults)
    }

    setLiveScoreState(liveScoreState)
    if (!isLiveScoreStateRetrived) setIsLiveScoreStateRetrived(true)
  }

  const refreshSignaRFunc = () => {
    hubConnectionLeagueMatch.connection.stop().then(() => {
      setHubConnectionLeagueMatch(undefined)
      setHasConnectSignalRLeagueMatch(false)
    })
  }
  const onLiveScoreAndCourtStateChanged = () => {
    if (hubConnectionLeagueMatch) {
      if (!isLiveScore && liveScoreState) {
        individualMatchSendResult(hubConnectionLeagueMatch, liveScoreState, courtData)
      }
    }
  }
  useEffect(() => {
    if (lodash.isEmpty(hubConnectionLeagueMatch?.connection?.connectionId) && hasConnectSignalRLeagueMatch) {
      initialSignalRConnection()
    }
    dispatch(signalRStateActions.setState(hubConnectionLeagueMatch?.connection, refreshSignaRFunc))
  }, [hubConnectionLeagueMatch?.connection])
  useEffect(onLiveScoreAndCourtStateChanged, [liveScoreState?.matchResults, courtData?.courtId, hubConnectionLeagueMatch?.connection?.connectionId])

  const individualMatchSendResult = (hubConnection: MatchHubConnection, liveScoreState: LiveScoreMatchState,
    courtData: CourtData) => {
    if (!props.isReadonly) {
      const combinedLiveScoreState: LiveScoreMatchState = {
        ...liveScoreState,
        courtExtId: courtData?.courtExtId,
        courtId: courtData?.courtId,
        courtNumber: courtData?.courtNumber,
        venueId: courtData?.venueId
      }
      hubConnection.connection.invoke("matchSendResult", combinedLiveScoreState).catch(error => {
        setHasConnectSignalRLeagueMatch(false)
      });
    }
  }

  const onCourtExtIdChanged = (newCourtData: CourtData) => {
    if (!lodash.isEqual(newCourtData, courtData)) {
      setCourtData(newCourtData)
    }
  }

  const onLiveScoreStateChanged = (matchResults: MatchSetResult[], playersFiledLocation: PlayersFiledLocation, currentPlayerServeId: number) => {

    const liveScoreState: LiveScoreMatchState = {
      matchResults,
      venueId: courtAssigmentState.model?.venueId,
      courtNumber: courtAssigmentState.model?.courtNumber,
      playersLocation: playersFiledLocation,
      parentMatchId: leagueMatchId,
      matchId: leagueMatchIndividualMatchIdNumber,
      currentPlayerServeId
    }
    setLiveScoreState(liveScoreState)

  }

  const leagueMatchState = useSelector<RootState>((state) => {
    return state.leagueMatch.leagueMatch;
  }) as ReducerStateProperties<LeagueMatchAggregateViewModel>


  const [errors, setErrors] = React.useState(null)
  const errorState = useSelector<RootState>((state) => {
    const { errors } = state.leagueMatch.leagueMatch;
    return errors;
  }) as any;



  React.useEffect(() => {
    if (!lodash.isEmpty(errorState)) {
      setErrors(errorState)
      dispatch(responseHandlerActions.call({ error: { appError: errorState.errors } }))
    }
  }, [errorState])

  const getMatchSetResult = (): MatchSetResult[] => {
    const { model } = leagueMatchState;

    const initialResult = [{
      score1: 0,
      score2: 0,
      setIndex: 0
    }];
    if (!model) {
      return initialResult
    }
    const individualMatch = model?.combinedTeamMatches?.find(x => x.leagueMatchIndividualMatchId === leagueMatchIndividualMatchIdNumber);
    const result = individualMatch?.leagueMatchSet.map(x => {
      const result: MatchSetResult = {
        score1: x?.points1,
        score2: x?.points2,
        setIndex: x?.setIndex
      };
      return result;
    });

    return result.length > 0 ? result : initialResult;
  }

  const [matchResultFromMatchCounterVariable, setMatchResultFromMatchCounterVariable]
    = useState<MatchSetResult[]>([{ setIndex: 0, score1: 0, score2: 0 }]);


  const getMatchPlayers = (): MatchPlayers => {
    const { model } = leagueMatchState;
    if (!model) {
      return;
    }
    const { leagueGroupTeamId1, leagueGroupTeamId2 } = model.match;
    const individualMatch = model.combinedTeamMatches?.find(x => x.leagueMatchIndividualMatchId === leagueMatchIndividualMatchIdNumber);

    const homeTeamViewModel = individualMatch?.teamPlayers[leagueGroupTeamId1];
    const awayTeamViewModel = individualMatch?.teamPlayers[leagueGroupTeamId2];
    if (homeTeamViewModel.length == 0 || awayTeamViewModel.length == 0)
      return;
    const awayTeam = processMatchTeam(awayTeamViewModel)
    const homeTeam = processMatchTeam(homeTeamViewModel)
    return {
      awayTeam,
      homeTeam
    }
  }

  const processMatchTeam = (playerMatchViewModel: LeagueMatchPlayerViewModel[]) => {
    let matchTeam: MatchTeam;
    playerMatchViewModel?.forEach((item, index) => {
      const { name, clubId } = item.player
      if (index === 0) {

        matchTeam = {
          ...matchTeam,
          clubId: clubId,
          player1: {
            name: `${name}`,
            playerId: item.playerId,
            clubId: clubId
          }
        }
      }
      if (index === 1) {
        matchTeam = {
          ...matchTeam,
          player2: {
            name: `${name}`,
            playerId: item.playerId,
            clubId: clubId
          }
        }
      }
    })
    return matchTeam;
  }

  const handleSubmit = (event: MatchSetResult[]) => {
    if (props.isReadonly) {
      return;
    }
    const leagueMatchSets = match.match.model?.combinedTeamMatches?.find(x => x.leagueMatchIndividualMatchId === leagueMatchIndividualMatchIdNumber)
      ?.leagueMatchSet;
    const newLeagueMatchSet = event.map(item => {
      const set = leagueMatchSets.find(x => x.setIndex == item.setIndex)
      let result: LeagueMatchSet;
      if (!lodash.isEmpty(set)) {
        result = {
          ...set,
          points1: item.score1,
          points2: item.score2
        }
      } else {
        result = {
          leagueMatchIndividualMatchId: leagueMatchIndividualMatchIdNumber,
          points1: item.score1,
          points2: item.score2,
          setIndex: item.setIndex,
          teamIndex1: 0,
          teamIndex2: 1
        }
      }
      return result;
    })
    const newMatch = Object.assign({}, match.match.model)
    newMatch.combinedTeamMatches.forEach(x => {
      if (x.leagueMatchIndividualMatchId === leagueMatchIndividualMatchIdNumber) {
        x.leagueMatchSet = newLeagueMatchSet
      }
    })

    dispatch(leagueMatchActions.updateLeagueMatch(leagueMatchId, newMatch, password))
  }

  const handleNextMatch = (leagueMatchIndividualMatchId: number) => {
    if (!props.isReadonly) {
      let url = `${urlPath}/leagueMatch/counter/${leagueMatchId}/${leagueMatchIndividualMatchId}/${password}`
      navigate(getPage(url, props.isLivescore))
      window.location.reload()
    }
  }

  const matchResultFromMatchCounter = (matchResult: MatchSetResult[]) => {
    setMatchResultFromMatchCounterVariable(matchResult);
  }

  const matchResultFromMatchCounterResult = () => {
    return matchResultFromMatchCounterVariable;
  }

  return (
    <React.Fragment>
      {/* Label for debugging purpose */}
      {/* <label>CONNECTION_ID {hubConnectionLeagueMatch?.connection.connectionId}</label> */}
      {isLiveScore && match.match.model && liveScoreState &&
        <MatchPointsViewer initMatchSetResult={liveScoreState.matchResults} matchPlayers={getMatchPlayers()} />
      }
      {
        !isLiveScore && match.match.model && getMatchSetResult() && getMatchPlayers() && isLiveScoreStateRetrived &&
        <React.Fragment>
          <MatchCounter
            isUmpire={props.isUmpire}
            isReadonly={props.isReadonly}
            liveScoreState={liveScoreState}
            onStateChange={onLiveScoreStateChanged}
            initMatchSetResult={matchResultFromMatchCounterResult()}
            matchPlayers={getMatchPlayers()}
            isLoading={leagueMatchState.isLoading}
            onChange={handleSubmit}
            matchResultFromMatchCounter={matchResultFromMatchCounter}
            isLeagueMatch={true}
            isTournament={false}
          />

          <NextCounterIndividualMatch
            handleCourtStateChange={onCourtExtIdChanged}
            onRemoveAssigment={() => individualMatchSendResult(hubConnectionLeagueMatch, undefined, courtData)}
            isReadonly={props.isReadonly}
            currentLeagueMatchIndividualMatchId={leagueMatchIndividualMatchIdNumber}
            handleNextMatch={handleNextMatch} match={match?.match?.model} />
        </React.Fragment>
      }

    </React.Fragment>
  );
}

export interface LeagueMatchSetPoints {
  home: number,
  away: number
}

export interface LiveScoreMatchState {
  parentMatchId: number,
  matchId: any,
  playersLocation: PlayersFiledLocation,
  matchResults: MatchSetResult[]
  currentPlayerServeId?: number,
  venueId?: number,
  courtNumber?: number,
  courtExtId?: string,
  courtId?: number,
  exceptions?: LivescoreSignalRException[]
}

export interface LivescoreSignalRException {
  message: string,
  priority: SignarRExceptionPriority
}

export enum SignarRExceptionPriority {
  High = 0,
  Medium
}

export interface LiveScoreCourtData {
  venueId: number,
  courtNumber: number
}

export interface CourtAssigmentState {
  isInit: boolean,
  model: CourtLeagueMatchIndividualMatchResponse
}

export interface MatchHubConnection {
  groupId: any;
  connection: signalR.HubConnection
}

